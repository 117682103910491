import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectPoll, voteForPoll } from "./pollSlice";
import { useReduxDispatch } from "../../app/store";

interface Props {
  id: string;
}

export function CastPollVote({ id }: Props) {
  const poll = useSelector(selectPoll);
  const dispatch = useReduxDispatch();
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const selected = poll.options.find((o) => o.id === selectedOptionId);

        // We have to provide options with ids.
        if (selected && selected.id) {
          dispatch(voteForPoll(selected.id, id));
        }
      }}
      className="mt-2"
    >
      {poll.options.map((o, i) => {
        return (
          <div key={i}>
            <label className="inline-flex items-center text-xl cursor-pointer">
              <input
                onChange={(e) => {
                  setSelectedOptionId(o.id || null);
                }}
                type="radio"
                className="form-radio cursor-pointer"
                name="radio"
                checked={selectedOptionId === o.id}
              />
              <span className="ml-2">{o.title}</span>
            </label>
          </div>
        );
      })}
      <button className="mt-6 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
        Vote!
      </button>
    </form>
  );
}
