import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectPoll, getPollFromServer, voteForOption } from "./pollSlice";
import { useReduxDispatch } from "../../app/store";
import { selectUser } from "../user/userSlice";
import { CastPollVote } from "./CastPollVote";
import { ViewPollResults } from "./ViewPollResults";
import { useSocket, SocketSubscriptionType } from "../../utils/socket-utils";

interface Props {
  children?: JSX.Element[];
}

export function ViewPoll(props: Props) {
  const user = useSelector(selectUser);
  const { id } = useParams<{ id: string }>();
  const poll = useSelector(selectPoll);
  const dispatch = useReduxDispatch();

  // Establish a listener to our web socket.
  useSocket<string>(SocketSubscriptionType.Votes, (voteId) => {
    dispatch(voteForOption(voteId));
  });

  if (!poll.pollId) {
    dispatch(getPollFromServer(id));

    // Display a loading indicator until the above thunk is resolved by redux
    return <div>Loading poll...</div>;
  }

  return (
    <div className="block">
      <div className="text-3xl pb-1 border-b-2 border-gray-600">View Poll</div>
      <div className="text-2xl mt-5">{poll.title}</div>
      {user.votedPollIDs.includes(id) ? (
        <ViewPollResults id={id} />
      ) : (
        <CastPollVote id={id} />
      )}
    </div>
  );
}
