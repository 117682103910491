import React from "react";
import "./App.css";
import { CreatePoll } from "./features/poll/CreatePoll";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ViewPoll } from "./features/poll/ViewPoll";
import { getObjectFromLocalStorage } from "./utils/storage-helpers";
import { useReduxDispatch } from "./app/store";
import { setVotedPolls } from "./features/user/userSlice";

function App() {
  const dispatch = useReduxDispatch();
  // Check user local storage for votes - if there are any, set these as voted by the user in the store.
  const votes = getObjectFromLocalStorage("pollVotes");
  if (votes && votes.length) {
    dispatch(setVotedPolls(votes));
  }

  return (
    <Router>
      <div className="grid md:grid-cols-5 mx-3 font-sans">
        <div className="md:col-start-2 md:col-end-5">
          <Switch>
            <Redirect to="/create" from="/" exact />
            <Route path="/create">
              <CreatePoll />
            </Route>
            <Route path="/poll/:id">
              <ViewPoll />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
