import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import { setUserVotedForPoll } from "../user/userSlice";

/**
 * Singular source of truth for the user's current poll.
 *
 * Discerned through the URL.
 */
export type Poll = {
  pollId?: string;
  title: string;
  options: PollOption[];
};

interface PollOption {
  id?: string; // Added by server
  title: string;
  votes: number;
}

export type PollReducers = {
  addPoll: (state: Poll, action: PayloadAction<Poll>) => Poll;
  voteForOption: (state: Poll, action: PayloadAction<string>) => Poll;
};

const initialState = {
  title: "",
  options: [],
};

export const pollSlice = createSlice<Poll, PollReducers>({
  name: "poll",
  initialState,
  reducers: {
    addPoll: (state, action) => {
      return {
        pollId: action.payload.pollId,
        title: action.payload.title,
        options: action.payload.options,
      } as Poll;
    },
    voteForOption: (state, action) => {
      return {
        ...state,
        options: state.options.map((option) => {
          if (option.id === action.payload) {
            return {
              ...option,
              votes: option.votes + 1,
            };
          }

          return option;
        }),
      };
    },
  },
});

export const { addPoll, voteForOption } = pollSlice.actions;

export const addPollToServer = (poll: Poll): AppThunk => async (dispatch) => {
  if (poll === null) {
    return;
  }

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/poll`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(poll),
  }).then(async (res) => {
    return dispatch(addPoll(await res.json()));
  });
};

export const voteForPoll = (vote: string, pollId: string): AppThunk => async (
  dispatch
) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/poll/${pollId}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ vote, pollId }),
  }).then(async (res) => {
    await res.json();
    dispatch(setUserVotedForPoll(pollId));
    // Note: we don't actually run the `voteForOption` reducer at all, because web sockets handle that.
  });
};

export const getPollFromServer = (id: string): AppThunk => async (dispatch) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/polls/${id}`).then(
    async (res) => {
      const apiPoll = await res.json();

      dispatch(addPoll(apiPoll));
    }
  );
};

export const selectPoll = (state: RootState) => {
  return state.poll;
};

export default pollSlice.reducer;
