import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectPoll } from "./pollSlice";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import { toPercentage } from "../../utils/utils";

const COLOURS = ["#4287f5", "#d94c4c", "#62e359", "#a258d6", "#e0c446"];

function getColourFromIndex(index: number): string {
  while (index >= COLOURS.length) {
    index -= COLOURS.length;
  }

  return COLOURS[index];
}

interface Props {
  id: string;
}

export function ViewPollResults({ id }: Props) {
  const poll = useSelector(selectPoll);
  const totalNumVotes = poll.options.reduce<number>(
    (acc, o) => acc + o.votes,
    0
  );
  const shareRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      <ResponsiveContainer height={250} width="100%">
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={poll.options}
            dataKey="votes"
            nameKey="title"
            outerRadius={75}
            fill="blue"
            labelLine={false}
            label={(props) => {
              return !!props.value
                ? props.name + ` (${toPercentage(props.value, totalNumVotes)}%)`
                : null;
            }}
          >
            {poll.options.map((option, i) => {
              return <Cell key={i} fill={getColourFromIndex(i)} />;
            })}
          </Pie>
          <Tooltip
            formatter={(value) => `${value} vote${value === 1 ? "" : "s"}`}
          />
        </PieChart>
      </ResponsiveContainer>

      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2">Option</th>
            <th className="px-4 py-2"># Votes</th>
            <th className="px-4 py-2">% Share</th>
          </tr>
        </thead>
        <tbody>
          {poll.options.map((option, i) => {
            return (
              <tr key={i}>
                <td className="border px-4 py-2">{option.title}</td>
                <td className="border px-4 py-2">
                  {option.votes} vote
                  {option.votes === 1 ? "" : "s"}
                </td>
                <td className="border px-4 py-2">
                  {toPercentage(option.votes, totalNumVotes)}%
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <div className="flex items-end border-b border-blue-800 py-1">
        <div className="w-full">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="share-link"
          >
            Share
          </label>
          <input
            ref={shareRef}
            id="share-link"
            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
            type="text"
            value={window.location.href}
            readOnly
            aria-label="Full name"
          />
        </div>
        <button
          className="flex-shrink-0 border-transparent border-4 text-blue-800 hover:border-radius-1 hover:bg-gray-300 hover:text-blue-700 text-sm py-1 px-2 rounded"
          type="button"
          onClick={(e) => {
            shareRef?.current?.select();
            document.execCommand("copy");

            // @TODO: show snackbar
          }}
        >
          Copy
        </button>
      </div>
    </div>
  );
}
