import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addPollToServer, selectPoll } from "./pollSlice";
import { Redirect } from "react-router-dom";
import { useReduxDispatch } from "../../app/store";
import Select from "../../components/Select";

const NUM_INITIAL_OPTS = 3;
const SPAM_PROTECTION_OPTIONS = [
  { key: "LOCAL", value: "Local Storage" },
  // { key: "IP", value: "IP Duplication Checking" }, @TODO
];

export function CreatePoll() {
  const [state, setState] = useState({
    title: "",
    spamProtectionType: SPAM_PROTECTION_OPTIONS[0].key,
    options: Array.from(Array(NUM_INITIAL_OPTS)).map((_, i) => ({
      title: "",
      id: i,
    })),
  });
  const [redirect, setRedirect] = useState(false);
  const dispatch = useReduxDispatch();
  const poll = useSelector(selectPoll);

  if (redirect) {
    return <Redirect push to={`poll/${poll?.pollId}`} />;
  }

  function onVoteOptionChange(i: number) {
    return (e: React.FormEvent<HTMLInputElement>) => {
      e.preventDefault();
      setState({
        ...state,
        options: state.options.map((option, _i) => {
          if (_i === i) {
            return { ...option, title: e.currentTarget.value };
          }

          return option;
        }),
      });
    };
  }

  function onAddOption(e: React.SyntheticEvent) {
    e.preventDefault();
    setState({
      ...state,
      options: [...state.options, { title: "", id: state.options.length }],
    });
  }

  function onCreatePoll(e: React.SyntheticEvent) {
    e.preventDefault();

    const addedPoll = {
      ...state,
      totalNumVotes: 0,
      options: state.options.map((option) => {
        return {
          title: option.title,
          votes: 0, // Start with no votes
        };
      }),
    };

    dispatch(addPollToServer(addedPoll)).then(() => {
      setRedirect(true);
    });
  }

  return (
    <div>
      <h2 className="text-3xl pb-1 border-b-2 border-gray-600">
        Create a poll
      </h2>

      <form className="mt-5" onSubmit={onCreatePoll}>
        <input
          onChange={(e) => {
            e.preventDefault();
            setState({ ...state, title: e.currentTarget.value });
          }}
          value={state.title}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          placeholder="Poll name"
        />
        {state.options.map((opt, i) => (
          <div className="flex" key={i}>
            <input
              required
              id={i.toString()}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
              type="text"
              onChange={onVoteOptionChange(i)}
              value={state.options[i].title}
              placeholder={`Option ${i + 1}`}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setState({
                  ...state,
                  options: state.options.filter((o) => o !== opt),
                });
              }}
              type="button"
              className="fill-current w-4 h-4 mr-2"
            >
              <svg
                className="mt-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z" />
              </svg>
            </button>
          </div>
        ))}
        <br />

        <button
          type="button"
          onClick={onAddOption}
          className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Add option
        </button>
        <br />

        <Select
          onChange={(e) => {
            setState({ ...state, spamProtectionType: e.currentTarget.value });
          }}
          options={SPAM_PROTECTION_OPTIONS}
          label="Spam Protection"
        />
        <br />

        <button
          disabled={state.options.length === 0}
          className={`bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow ${
            state.options.length === 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Create
        </button>
      </form>
    </div>
  );
}
