import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { setObjectInLocalStorage } from "../../utils/storage-helpers";

export interface User {
  votedPollIDs: string[];
}

export type UserReducers = {
  setUserVotedForPoll: (state: User, action: PayloadAction<string>) => User;
  setVotedPolls: (state: User, action: PayloadAction<string[]>) => User;
};

const initialState = {
  votedPollIDs: [],
};

export const userSlice = createSlice<User, UserReducers>({
  name: "user",
  initialState,
  reducers: {
    setUserVotedForPoll: (state, action) => {
      const voted = [...state.votedPollIDs, action.payload];

      setObjectInLocalStorage("pollVotes", voted);

      return {
        votedPollIDs: voted,
      };
    },
    setVotedPolls: (state, action) => {
      return {
        votedPollIDs: action.payload
      }
    }
  },
});

export const { setUserVotedForPoll, setVotedPolls } = userSlice.actions;

export const selectUser = (state: RootState) => {
  return state.user;
};

export default userSlice.reducer;
