import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import pollReducer from "../features/poll/pollSlice";
import userReducer from "../features/user/userSlice";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    poll: pollReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = Promise<any>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type ReduxDispatch = ThunkDispatch<RootState, any, Action<string>>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
