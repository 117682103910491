import React, { ChangeEvent } from "react";

interface Props {
  label: string;
  value?: string;
  options?: { key: string; value: string }[];
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export function Select({ label, value, options, onChange }: Props) {
  return (
    <div className="inline-block relative w-64 mb-4">
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="grid-state"
      >
        {label}
      </label>
      <div className="relative">
        <select
          onChange={onChange}
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          {options?.map((o, i) => (
            <option key={i} value={o.key}>
              {o.value}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
}
