import openSocket from "socket.io-client";
const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

export function subscribeToSocket<T = any>(
  socketName: string,
  onSocketEmit: (data: T) => void
): void {
  socket.on(socketName, (data: T) => onSocketEmit(data));
}

export function unsubscribeFromSocket(socketName: string) {
  socket.removeListener(socketName);
}
