import { useEffect } from "react";
import {
  subscribeToSocket,
  unsubscribeFromSocket,
} from "../api/votes-update-listener";

export enum SocketSubscriptionType {
  Votes = "votes",
}

export function useSocket<T = any>(
  socketName: SocketSubscriptionType,
  onSubscribe: (data: T) => void
) {
  useEffect(() => {
    subscribeToSocket(socketName, onSubscribe);

    // Remove remaining listeners on dismount.
    return () => {
      unsubscribeFromSocket(socketName);
    };
  });
}
